<template>
  <div class="financialStatement">
    <NavBar :list="navList" name="财务报表"></NavBar>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'financialStatement',
  data () {
    return {
      navList: [
        { name: '商户财务报表', path: '/finance/financialStatement/merchantList' },
        { name: '商铺财务报表', path: '/finance/financialStatement/shopList' }
      ]
    }
  }
}
</script>
<style lang="scss" scoped></style>
